@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;900&display=swap);
/* Universal */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  max-width: 100%;
}

body {
  font-family: "Roboto", sans-serif;
  overflow-x: hidden;
  position: relative;
  /* background: #e1e1e1; */
}

html {
  /* color: #ffffff; */
  font-size: 62.5%;
  overflow-x: hidden;
}

h1 {
  font-size: 3rem;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

h2 {
  font-style: normal;
  font-weight: 300;
  font-size: 2.5rem;
  line-height: 21px;
}

h3 {
  font-style: normal;
  font-weight: 900;
  font-size: 2rem;
}

h4 {
  font-style: normal;
  font-weight: 400;
  font-size: 3rem;
}

p {
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

i {
  font-size: 1.6rem;
  font-style: italic;
  font-weight: 400;
  line-height: 30px;
}

li {
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.cornerTag {
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 1%;
}

/* App.js */
.App {
  text-align: left;
}

/* Nav.js */
.allLinks {
  color: #000000;
  font-style: normal;
  font-size: 1.4rem;
  text-decoration: none;
  transition: all 0.3s ease;
  width: 100%;
  padding: 1%;
}

.allLinks:hover {
  border-radius: 5px;
  background: #696969;
  color: white;
  cursor: pointer;
}

nav {
  text-align: center;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.75);
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 1.5% auto;
  padding: 0.25%;
  width: 750px;
  z-index: 2;
  position: fixed;
}

/* Nav px to % starts here */

@media (max-width: 1000px) {
  nav {
    margin: 0 auto;
    width: 100%;
    border-radius: 0px;
    box-shadow: none;
    padding: 0.75%;
  }
}

/* Nav px to % ends here */

#myNav {
  display: flex;
  justify-content: center;
}

/* Footer.js */
footer {
  color: #000000;
  margin: 3% 0 1.5% 0;
  padding: 1%;
  text-align: center;
}

/* Home.js */
#home {
  padding-top: 9%;
}

.homeMainBox {
  margin: 0 auto;
  background: #b57272;
  width: 1250px;
  height: 750px;
}

.homeContent {
  width: 100%;
  height: 93%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.homeContentLeft {
  padding: 3%;
  width: 50%;
}

.homeContentLeft h2 {
  margin: 10% 0%;
}

.myself {
  width: 250px;
  margin: 0 auto;
}

.homeContentRight {
  padding: 2.5%;
  width: 38%;
}

.homeContactBtn {
  display: flex;
  justify-content: center;
  margin-top: 10%;
}

.homeContactBtn a {
  text-decoration: none;
  cursor: pointer;
  border: 1px solid #ffffff;
  color: #ffffff;
  background: none;
  width: 250px;
  height: 30px;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.homeSchoolLink {
  color: #ffffff;
}

/* Home media queries starts here */

@media (max-width: 750px) {
  .homeContent {
    flex-direction: column;
  }

  .homeContentLeft {
    padding: 2% 2% 0 2%;
    width: 750px;
  }

  .homeContentRight {
    padding: 0 2% 0.5% 2%;
    width: 750px;
  }

  .homeContactBtn {
    display: none;
  }
}

@media (max-width: 525px) {
  h1 {
    font-size: 2.8rem;
  }

  h2 {
    font-size: 2.2rem;
  }
  .homeContentLeft h2 {
    margin: 5% 0%;
  }
  .myself {
    width: 175px;
  }
}

/* Home media queries ends here */

/* Work.js */
#work {
  padding-top: 9%;
}

.workMainBox {
  margin: 0 auto;
  background: #b59a72;
  width: 1250px;
  height: 750px;
}

/* Desktop Shown / Hide Starts Here */

.workContent {
  width: 100%;
  height: 93%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-container {
  height: 700px;
}

.swiper-slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.workImg {
  width: 800px;
}

.swiper-button-next,
.swiper-button-prev {
  -webkit-filter: drop-shadow(1px 1px 3px #8e8e8e);
          filter: drop-shadow(1px 1px 3px #8e8e8e);
}

.workBtn {
  text-decoration: none;
  cursor: pointer;
  border: 1px solid #ffffff;
  color: #ffffff;
  background: none;
  width: 250px;
  height: 30px;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
}

@media (max-width: 500px) {
  .workImg {
    width: 400px;
  }
  .workBtn {
    margin-top: 15%;
  }
}

/* Desktop Shown / Hide Ends Here */

/* Skill.js */
#skill {
  padding-top: 9%;
}

.skillMainBox {
  margin: 0 auto;
  background: #a3b572;
  width: 1250px;
  height: 750px;
}

.skillContent {
  width: 100%;
  height: 93%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.skillNavPlacement {
  display: flex;
  justify-content: center;
}

.skillNav {
  width: 85%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.skillNavBtn {
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 19px;
  width: 100%;
  padding: 1.5%;
  cursor: pointer;
  text-align: center;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  border-bottom-width: 4px;
  border-bottom-style: solid;
}

.skillNavBtnActive {
  border-bottom-color: #ffffff;
  transition: 0.75s ease;
}

.skillDisplayPlacement {
  display: flex;
  justify-content: center;
}

.skillDisplayRevealText {
  text-align: center;
  width: 90%;
  font-style: normal;
  font-weight: bold;
  font-size: 2.2rem;
  line-height: 28px;
}

.skillDisplayHideText {
  display: none;
}

.skillDisplayRevealText li {
  list-style: none;
  list-style-type: none;
}

.skillResumeBtn {
  display: flex;
  justify-content: center;
  margin: 5% 0;
}

.skillResumeBtn button {
  color: #ffffff;
  border: 1px solid #ffffff;
  background: none;
  width: 250px;
  height: 30px;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 16px;
}

/* Story.js */
#story {
  padding-top: 9%;
}

.storyMainBox {
  margin: 0 auto;
  background: #d5bc69;
  width: 1250px;
  height: 750px;
}

.storyContent {
  width: 100%;
  height: 93%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.storyText {
  padding: 0 3% 0 3%;
  margin-top: 5%;
}

@media (max-width: 500px) {
  .storyText {
    padding: 0 8% 0 8%;
  }
}

/* Contact.js */
#contact {
  padding-top: 9%;
}

.contactMainBox {
  margin: 0 auto;
  background: #72a5b5;
  width: 1250px;
  height: 750px;
}

.contactContent {
  width: 100%;
  height: 93%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.contactInfoPlacement {
  text-decoration: none;
  color: #ffffff;
  margin: 1.5%;
  width: 500px;
  height: 100px;
  background: #8e8e8e;
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 19px;
  display: flex;
  align-items: center;
}

.contactTextStack {
  width: 100%;
  height: 100px;
  padding: 1.25%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.contactTextTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 19px;
}

.contactTextInfo {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.contactIcon {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #777777;
}

.contactTopBtn a {
  text-decoration: none;
  cursor: pointer;
  border: 1px solid #ffffff;
  color: #ffffff;
  background: none;
  width: 250px;
  height: 30px;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
}

/* WorkCardInfo.js */
.workPage {
  background: #b59a72;
  padding: 2.5% 2% 5% 2%;
}

.workPageContainer {
  background: rgba(0, 0, 0, 0.5);
  padding: 2.5%;
  width: 1300px;
  margin: 0 auto;
}

@media (max-width: 1300px) {
  .workPageContainer {
    background: none;
    padding: 0.25%;
    width: none;
    margin: auto;
  }
}

.workPageCornerTag {
  display: flex;
  cursor: pointer;
  width: 125px;
}

.workPageTitle {
  text-align: center;
  font-style: normal;
  font-weight: 300;
  font-size: 2.2rem;
  line-height: 30px;
  margin: 7%;
}

.workPageBtn {
  margin: 7% 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.workPageBtn a {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 125px;
  height: 30px;
  background: none;
  border: 1px solid #ffffff;
  color: #ffffff;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 16px;
}

.workPageList {
  margin-top: 3%;
  margin-left: 3%;
}

.workPageList li {
  list-style-type: square;
}

/* Resume.js */
iframe {
  display: block;
  width: 100%;
  border: none;
  overflow-y: auto;
  overflow-x: hidden;
}

